import React, { useState } from 'react'

import styled from 'styled-components'

import { FiChevronRight } from 'react-icons/fi'

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Chevron = styled(FiChevronRight)`
    transition: 0.3s ease;
    transform: ${props => (props.expanded ? 'rotate(90deg)' : 'rotate(0)')};
    font-size: 2rem;
`

const Title = styled.h2`
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
`

const Text = styled.p`
    transition: all 0.3s ease;
    max-height: ${props => (props.expanded ? 200 : 0)}px;
    overflow: hidden;
    opacity: ${props => (props.expanded ? 1 : 0)};
    margin: 0;
    margin-top: ${props => (props.expanded ? '1rem' : 0)};
    line-height: 140%;
`

const ItemContainer = styled.div`
    padding: 1rem;
    background-color: ${props => props.theme.gray3};
    margin-bottom: 0.5rem;
`

const faqs = [
    {
        title: 'What is verifeco?',
        text:
            'Verifeco is online service to exchange (verified) business cards.',
    },
    {
        title: 'Is it free of charge?',
        text:
            'Yes it’s totally free to create your business card and share it unlimited number of time.',
    },
    {
        title: 'How do I get the verified badge?',
        text:
            'You may email us for now, our automated process is under the way.',
    },
]

const Item = ({ faq }) => {
    const [expanded, setExpanded] = useState(false)

    const toggle = () => setExpanded(prev => !prev)
    return (
        <ItemContainer key={faq.title}>
            <TitleContainer onClick={() => toggle()}>
                <Title>{faq.title}</Title>
                <Chevron expanded={expanded} />
            </TitleContainer>
            <Text expanded={expanded ? 1 : 0}>{faq.text}</Text>
        </ItemContainer>
    )
}

export default () => {
    return faqs.map((faq, i) => <Item key={faq.title + i} faq={faq} />)
}
